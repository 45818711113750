import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './table-of-contents.module.css'
import Layout from '../../vscodevimbook/Layout'
import SEO from '../../components/seo'
import AgnosticLink from '../../components/AgnosticLink'

export default class TableOfContents extends React.Component {
  render() {
    const { data } = this.props
    // BUG: According to the page query below, these should already be appropriately sorted.
    // And that's how it works when running `gatsby develop`, but when you use a production
    // build with `gatsby build` it doesn't work. All chapters appear unsorted. Until I can
    // figure out how to fix it properly I'll sort the chapters in this component as well.
    // The sort below should not be necessary.
    const chapters = data.allMarkdownRemark.edges
      .map((e) => e.node)
      .sort((a, b) => a.frontmatter.chapter > b.frontmatter.chapter)
    const half = Math.ceil(chapters.length / 2) + 1
    const firstHalf = chapters.slice(0, half)
    const secondHalf = chapters.slice(half, chapters.length)
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout>
        <article className={styles.page}>
          <SEO
            title={`Boost Your Coding Fu With VSCode and Vim - Table Of Contents | ${siteTitle}`}
          />
          <section className={styles.content}>
            <h1>Table Of Contents</h1>
            <section className={styles.chapters}>
              <Chapters chapters={firstHalf} />
              <Chapters chapters={secondHalf} />
            </section>
          </section>
        </article>
      </Layout>
    )
  }
}

const Chapters = ({ chapters }) => (
  <section>
    {chapters.map((c) => (
      <section className={styles.chapter} key={c.frontmatter.chapter}>
        <h2 className={styles.chapterheader}>
          <AgnosticLink href={c.fields.slug}>
            {c.frontmatter.chapter}. {c.frontmatter.title}
          </AgnosticLink>
        </h2>
        <p>{c.frontmatter.description}</p>
      </section>
    ))}
  </section>
)

export const pageQuery = graphql`
  query BoostCodingFuVSCodeVimTOCQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___chapter], order: ASC }
      filter: {
        frontmatter: {
          draft: { eq: false }
          book: { eq: "Boost Your Coding Fu With VSCode and Vim" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            chapter
            description
          }
          timeToRead
        }
      }
    }
  }
`
